import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext'; // Adjust path as needed

const RegisterView: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false); // New state for loading
    const navigate = useNavigate();
    const { register } = useApp();

    const handleRegister = async (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);
        setLoading(true); // Start loading

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false); // Stop loading
            return;
        }

        try {
            await register(email, password, rememberMe);
            navigate('/');
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/logo-no-background.png`} // Use your logo image path here
                    alt="Logo"
                    sx={{
                        height: 100, // Adjust the size as needed
                        mb: 2,
                    }}
                />
                <Typography component="h1" variant="h5">
                    Create an account
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading} // Disable inputs while loading
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading} // Disable inputs while loading
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        disabled={loading} // Disable inputs while loading
                    />
                    <FormControlLabel
                        control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} color="primary" />}
                        label="Remember me"
                        disabled={loading} // Disable checkbox while loading
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? 'Registering...' : 'Register'}
                    </Button>

                    <Box display="flex" justifyContent="center" mt={2}>
                        <Link component={RouterLink} to="/" variant="body2">
                            Already have an account? Sign in
                        </Link>
                    </Box>

                </Box>
            </Box>
        </Container>
    );
};

export default RegisterView;