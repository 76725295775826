import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingSpinner: React.FC = ({ }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw'
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingSpinner;