import { IProject } from '@models/Backend';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericModal from '../../components/GenericModal';
import { useApp } from '../../contexts/AppContext'; // Adjust path as needed
import CreateProjectForm from './modals/CreateProjectForm';

const DashboardView: React.FC = () => {
    const navigate = useNavigate();
    const { organization, setProject, fetchOrganization } = useApp();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = (project: IProject) => {
        setProject(project)
        navigate(`/project/${project._id}`);
    };

    const handleAddProject = () => {
        setIsModalOpen(true); // Open the modal when "Add Project" button is clicked
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        fetchOrganization(organization!._id)

    };

    return (
        <>
            {/* Project Header */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ height: '20px' }}>
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
                    <Typography sx={{ flexGrow: 1 }}>
                        Organization Home
                    </Typography>
                </Breadcrumbs>

                {/* Add Project Button */}
                <Box justifyContent="flex-end" display="flex" alignItems="center" sx={{ minWidth: "200px" }}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddProject}
                        startIcon={<AddIcon />}
                    >
                        Add Project
                    </Button>
                </Box>
            </Box>

            <Grid container>
                {organization?.projects === undefined ? (
                    // Display skeleton loader while loading
                    <>
                        {[1, 2].map((skeleton) => (
                            <Grid item xs={12} sm={12} md={6} key={skeleton} sx={{ padding: 2 }}>
                                <Card>
                                    <CardContent>
                                        <Skeleton variant="text" height={40} width="80%" />
                                        <Skeleton variant="text" height={30} width="60%" sx={{ mt: 2 }} />
                                        <Skeleton variant="rectangular" height={118} sx={{ mt: 2 }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </>
                ) : organization && organization.projects.length === 0 ? (
                    // Display a button if there are no projects
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ height: '20vh' }} // Center vertically and horizontally
                        >
                            There are no projects yet
                        </Box>
                    </Grid>
                ) : (
                    // Display the projects when available

                    organization?.projects
                        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()) // Sort by updatedAt in descending order
                        .map((projectItem: any) => (
                            <Grid item xs={12} sm={12} md={6} key={projectItem._id} sx={{ padding: 2 }}>
                                <Card onClick={() => handleCardClick(projectItem)} >
                                    <CardActionArea>
                                        <CardContent sx={{ cursor: 'pointer' }}>
                                            <Typography variant="h5" component="div">
                                                {projectItem.name}
                                            </Typography>
                                            <Typography variant="subtitle1" component="div">
                                                {projectItem.description}
                                            </Typography>
                                            {projectItem.suites.map((suite: any) => (
                                                <Box key={suite._id} sx={{ mt: 2 }}>
                                                    <Typography variant="subtitle2" component="div">
                                                        {suite.name}
                                                    </Typography>
                                                    <Typography sx={{ mb: 1.5 }} variant="caption" color="text.secondary">
                                                        Number of test cases: {suite.testCases.length}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </CardContent>
                                        <CardActions>
                                            {/* Add card actions if needed */}
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                )}
            </Grid>
            {/* Generic Modal for Create Project */}
            <GenericModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                content={<CreateProjectForm onSuccess={handleModalClose} organization={organization!} project={undefined} />}
                title="Create New Project"
            />

        </>
    );
};

export default DashboardView;