import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import { AppProvider } from './contexts/AppContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a437db',//'#7792c3',//'#1c1c1c',
    },
    secondary: {
      main: '#FFF',
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement // Ensure this is the correct element ID
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <App />
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
);
