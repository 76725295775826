import { Action } from '@models/Local';
import AssistantIcon from '@mui/icons-material/Assistant';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageComponent from '../components/ImageComponent';
import { useApp } from '../contexts/AppContext';
import { IFile } from '../models/Backend';
import BackendService, { AIgenerateModel } from '../services/BackendService';

interface Props {
    donateActionWords: (actions: Action[]) => void;
}

const DragAndDropUpload: React.FC<Props> = ({ donateActionWords }) => {
    const { organization, project } = useApp();
    const [files, setFiles] = useState<File[]>([]);
    const [description, setDescription] = useState<string>('');
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [images, setImages] = useState<IFile[]>([]);
    const [sampleText, setSampleText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<File | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [previewJSON, setPreviewJSON] = useState<any[]>();
    const testcaseId = "66c1292177c4c51b6ae73971";

    // Fetch images on component mount
    const fetchImages = async () => {
        if (project && organization) {
            try {
                setLoading(true);
                const fetchedImages = await BackendService.getAllImages(organization._id, project._id, testcaseId);
                setImages(fetchedImages.sort((a, b) => a.order - b.order));
            } catch (error: any) {
                console.error('Failed to fetch images:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchImages();
        setSampleText("la app esta cargando, le doy click a let's go, hago login, declino las notificaciones y continuo, veo mi nombre en la home")
    }, [organization, project]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setCurrentImage(acceptedFiles[0]);
        setModalOpen(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': [] },
        multiple: false,
    });

    const handleUpload = async () => {
        if (!currentImage) {
            setUploadStatus('No image selected');
            return;
        }

        if (description.trim() === '') {
            setUploadStatus('Description is required');
            return;
        }

        const formData = new FormData();
        formData.append('image', currentImage);
        formData.append('description', description);
        formData.append('order', (images.length + 1).toString()); // Next order value

        if (project && organization) {
            setUploading(true);
            setProgress(0);

            try {

                const response = await BackendService.uploadImage(organization._id, project._id, testcaseId, formData);
                setImages((prevImages) => [...prevImages, response]);
                setUploadStatus('Upload successful!');
                setDescription('');
            } catch (error: any) {
                setUploadStatus('Upload failed: ' + error.message);
            } finally {
                setUploading(false);
            }
        } else {
            setUploadStatus('No project or organization selected');
        }
    };

    const handleOnDragEnd = async (result: any) => {
        const { destination, source } = result;

        if (!destination) return;

        if (destination.index === source.index) return;

        const reorderedImages = Array.from(images);
        const [movedImage] = reorderedImages.splice(source.index, 1);
        reorderedImages.splice(destination.index, 0, movedImage);

        // TODO: Update image order on the server
        // await BackendService.updateImageOrder(organization._id, projectState._id, testcaseId, reorderedImages.map((img, index) => ({
        //     _id: img._id,
        //     order: index + 1
        // })));

        setImages(reorderedImages);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentImage(null);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleUpload();
        }
    };

    const handleCreateTestCase = async () => {
        console.log('Creating test case with sample text:', sampleText);
        // TODO: Implement test case creation logic
        const model: AIgenerateModel = {
            instructions: `${sampleText}`,
            credentials: {
                username: "user@name.com",
                password: "a password"
            }
        }

        if (project && organization) {
            const actions = await BackendService.AIGenerate(organization?._id, project?._id, testcaseId, model)
            setPreviewJSON(actions)
            donateActionWords(actions)
        }

    };

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
                <Typography variant='h5'>
                    AI Composer
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="outlined"
                    onClick={handleCreateTestCase}
                    fullWidth
                    startIcon={<AssistantIcon />}
                >
                    Generate with AI
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>
                    Prompt Script
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder='login using the given credentials and validate the home is shown'
                    value={sampleText}
                    onChange={(e) => setSampleText(e.target.value)}
                    sx={{ mb: 2 }}
                />

            </Grid>

            {/* Drag & Drop Uploader */}
            <Grid item xs={12}>
                <Box
                    {...getRootProps()}
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                        borderRadius: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                        width: '100%', // Ensure the Box takes full width
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        mb: 2,
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop the image here...</Typography>
                    ) : (
                        <Typography>Drag 'n' drop an image here, or click to select one</Typography>
                    )}
                </Box>
            </Grid>

            <>
                {loading ? (
                    Array.from(new Array(4)).map((_, index) => (
                        <Grid item xs={12} sm={12} md={6} xl={2}>
                            <Skeleton width='100%' variant="rounded" animation='wave' />
                        </Grid>
                    ))
                ) : (
                    images.map((image, index) => (
                        <Grid item xs={6} sm={4} md={3} xl={2} sx={{ mb: 2 }}>

                            <Box

                                sx={{ textAlign: 'center' }}
                            >
                                {image.description && <Typography variant="caption">{image.description}</Typography>}
                                <br />
                                <ImageComponent file={image} />


                            </Box>

                        </Grid>
                    ))
                )}
            </>

            {/* Modal for Image Description */}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        alignContent: 'cener',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" mb={2}>
                        Add Description and Upload
                    </Typography>

                    {/* Preview and Description Form */}
                    <Box mb={2}>
                        {currentImage && (
                            <Box textAlign="center">
                                <img
                                    src={URL.createObjectURL(currentImage)}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                                />
                                <br />
                                <Typography variant="caption" color="textSecondary">
                                    {currentImage.name}
                                </Typography>
                            </Box>
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Description"
                            autoComplete='false'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onKeyDown={handleKeyDown}
                            sx={{ mt: 2 }}
                        />
                    </Box>

                    {/* Progress and Submit Button */}
                    {uploading && <LinearProgress sx={{ mb: 2 }} variant="determinate" value={progress} />}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={uploading}
                        fullWidth
                    >
                        {uploading ? 'Uploading...' : 'Upload'}
                    </Button>


                </Box>
            </Modal>
        </Grid>
    );
};

export default DragAndDropUpload;