import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { TestCase } from '../../models/Local';

interface Props {
  testCase: TestCase;
  setTestCase: React.Dispatch<React.SetStateAction<TestCase>>;
}

const TestCaseBuilder: React.FC<Props> = ({ testCase, setTestCase }) => {
  const [name, setName] = useState(testCase.name);
  const [description, setDescription] = useState(testCase.description);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setTestCase({ ...testCase, name: e.target.value });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    setTestCase({ ...testCase, description: e.target.value });
  };

  return (
    <Box>
      <TextField required label="Name" variant="filled" value={name} onChange={handleNameChange} sx={{
        width: '100%', mt: 2
      }} />
      <TextField multiline label="Description" variant="filled" value={description} onChange={handleDescriptionChange} sx={{
        width: '100%', mt: 2
      }}
        rows={4}
      />
    </Box>

  );
};

export default TestCaseBuilder;