import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRoutes';
import LoadingSpinner from './components/LoadingSpinner';
import { useApp } from './contexts/AppContext'; // Import AuthProvider and useAuth

const App = () => {
    const { loading } = useApp();

    // Show loading spinner while checking auth status and fetching organization
    if (loading) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </Box>
    );
};

export default App;