import AssistantIcon from '@mui/icons-material/Assistant';
import BallotIcon from '@mui/icons-material/Ballot';
import BugReportIcon from '@mui/icons-material/BugReport';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Badge, { BadgeProps } from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AvatarMenu from '../components/AvatarMenu';
import NavItem from '../components/NavItem';

const drawerWidth = 240;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 10,
    padding: '0px',
  },
}));


const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface LayoutProps {
  showDrawer: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ showDrawer, children }) => {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const projectId = "66b6b23ada1ed492ac98bdb7"

  const items = [
    { text: 'Project', icon: <BallotIcon />, href: `/project/${projectId}` },
    { text: 'Test Cases', icon: <BugReportIcon />, href: `/project/${projectId}/create` },
    { text: 'Credentials', icon: <VpnKeyIcon />, href: `/project/${projectId}/credentials` },
    { text: 'Continous Integration', icon: <VerticalAlignBottomIcon />, href: `/project/${projectId}/ci` },
    { text: 'AI', icon: <AssistantIcon />, href: `/project/${projectId}/ai` },

  ]
  const itemsDown = [
    { text: 'Documentation', icon: <DescriptionIcon />, href: "/documentation" },
    { text: 'Help', icon: <HelpOutlineIcon />, href: "/help" },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogoClick = async () => {
    try {
      navigate('/')
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <Box sx={{
      flexGrow: 1,        // Allows this Box to grow and fill available space
      display: 'flex',    // Enable flexbox layout
      width: '100%',      // Ensure it takes up full width of its parent
    }}>

      <AppBar position="fixed" open={open}>
        <Toolbar>
          {showDrawer && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/logo-no-background-white.png`} // Use your logo image path here
            alt="Logo"
            onClick={handleLogoClick}
            sx={{
              cursor: 'pointer',
              height: 50, // Adjust the size as needed
            }}
          />
          <Box sx={{ flexGrow: 1 }} /> {/* Spacer */}
          <Tooltip title="Notifications" sx={{ mr: 2 }}>
            <StyledBadge badgeContent={4} color="success" variant="dot">
              <IconButton>
                <NotificationsIcon color="secondary" />
              </IconButton>
            </StyledBadge>
          </Tooltip>
          <AvatarMenu />
        </Toolbar>
      </AppBar>
      {showDrawer && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <NavItem key={"dashboard"} text={"Dashboard"} icon={<DashboardIcon />} open={open} link={'/'} />
          {location.pathname != "/" && (
            <List>
              {items.map(({ text, icon, href }) => (
                <NavItem key={text} text={text} icon={icon} open={open} link={href} />
              ))}
            </List>
          )}
          <Box mt="auto">
            <Divider sx={{ mb: 1 }} />
            <List>
              {itemsDown.map(({ text, icon, href }) => (
                <NavItem key={text} text={text} icon={icon} open={open} link={href} />
              ))}
              {open ? (
                <ListItem key="upgradePro" disablePadding sx={{ display: 'block' }}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      bgcolor: 'background.paper',
                      justifyContent: 'center',
                    }}
                  >
                    <Divider sx={{ mb: 1 }} />
                    <Button variant="contained" color="primary" sx={{ m: 1, p: 2, width: '222px' }}>
                      Upgrade to Pro
                    </Button>
                  </Box>
                </ListItem>
              ) : (
                <NavItem key="upgradeProIcon" text="Upgrade to pro" icon={<UpgradeIcon sx={{ fontSize: 40 }} />} open={open} link="/upgrade" />
              )}

            </List>
          </Box>
        </Drawer>
      )}
      <Box component="main" sx={{
        flexGrow: 1,        // Allows this Box to grow and fill available space
        p: 3,               // Padding around the content
        display: 'flex',    // Enable flexbox layout
        flexDirection: 'column', // Layout children in a column
        width: '100%',      // Ensure it takes up full width of its parent
      }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default Layout

export const fabStyle = {
  position: 'fixed',
  bottom: 5,
  right: 5,
};