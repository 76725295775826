import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { IOrganization, IProject } from '../../../models/Backend';
import BackendService from '../../../services/BackendService'; // Import AxiosService

interface CreateProjectFormProps {
  onSuccess: () => void;  // Callback prop to close the modal or handle other success actions  
  organization: IOrganization,
  project: IProject | null | undefined
}

const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
  onSuccess,
  organization,
  project
}) => {

  const [projectName, setProjectName] = useState(project?.name || '');
  const [projectDescription, setProjectDescription] = useState(project?.description || '');

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSaveProject = async () => {
    // Validate project name
    if (projectName.trim() === '') {
      setError('Project name is required');
      return;
    }

    // Reset error and start loading
    setError(null);
    setLoading(true);

    if (project) {
      handleUpdateProject(project)
    } else {
      handleCreateProject()
    }
  }

  const handleCreateProject = async () => {


    try {
      const project = await BackendService.createProject(organization!._id, { name: projectName, description: projectDescription })
      onSuccess(); // Close modal or handle success logic outside

      console.log('Project created:', projectName);
    } catch (err) {
      // Handle any errors during project creation
      setError('Failed to create project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProject = async (project: IProject) => {
    try {
      //TODO: Update the project
      const updated = await BackendService.updateProject(organization!._id, project._id, { name: projectName, description: projectDescription })
      onSuccess(); // Close modal or handle success logic outside

      console.log('Project updated:', projectName);
    } catch (err) {
      // Handle any errors during project creation
      setError('Failed to update project. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}

      <TextField
        label="Project Name"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        fullWidth
        required
        margin="normal"
        disabled={loading}
      />

      <TextField
        label="Project Description"
        value={projectDescription}
        onChange={(e) => setProjectDescription(e.target.value)}
        fullWidth
        margin="normal"
        disabled={loading}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveProject}
        fullWidth
        disabled={loading}
      >
        {project ? loading ? 'Updating...' : 'Update Project' : loading ? 'Creating...' : 'Create Project'}
      </Button>
    </Box>
  );
};

export default CreateProjectForm;