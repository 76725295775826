import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SxProps } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavItemProps {
  text: string;
  icon: React.ReactNode;
  open: boolean;
  link: string;
  sx?: SxProps;
}

const NavItem: React.FC<NavItemProps> = ({ text, icon, open, link, sx }) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(link)
  }

  return (
    <ListItem key={text} disablePadding sx={{ display: 'block', ...sx }} onClick={handleClick}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;