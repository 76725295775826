import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Action } from '../../models/Local';



interface Props {
  action: Action;
}

const ActionPreview: React.FC<Props> = ({ action }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem alignItems="flex-start" sx={{
      padding: 0, // Remove padding from ListItem

    }}
      secondaryAction={
        <div>
          <IconButton edge="end" aria-label="delete" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Edit</MenuItem>
            <MenuItem onClick={handleClose}>Move to top</MenuItem>
            <MenuItem onClick={handleClose}>Move up</MenuItem>
            <MenuItem onClick={handleClose}>Move down</MenuItem>
            <MenuItem onClick={handleClose}>Move bottom</MenuItem>
            <MenuItem onClick={handleClose} sx={{ color: 'red' }}>Delete</MenuItem>
          </Menu>
        </div>

      }>

      <ListItemText
        sx={{
          borderBottom: '1px solid #acacac',
          padding: '8px',
          borderRadius: '0px',
          userSelect: 'none',
          cursor: 'pointer',
          ":hover": {
            background: '#e6e6e6'
          },
          background: '#f8f8f8'

        }}
        primary={action.action}
        secondary={
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {action.parameters && action.parameters.length > 0 ? (
              action.parameters.map((param) => (
                <Box key={param.name}>
                  <strong>{param.name}</strong>: <i>{param.value}</i>
                </Box>
              ))
            ) : (
              <Box>No parameters available</Box>
            )}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ActionPreview;