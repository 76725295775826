import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IActionWord, IFile, IOrganization, IProject, ISuite, ITestCase, IUser } from '../models/Backend';
import { Action } from '../models/Local';

interface ApiResponse<T> {
  data: T;
  status: string,
  message?: string
}
const baseURL = process.env.REACT_APP_API_BASE_URL;

class BackendService {

  //private recoverSession: () => Promise<string | null>;
  private apiClient: AxiosInstance;
  private token: string | null = null;


  constructor() {
    this.apiClient = axios.create({
      baseURL: baseURL, // Adjust base URL as needed
    });
    //this.recoverSession = useApp().recoverSession;
    this.setupInterceptors();
  }

  private setupInterceptors() {
    // Request interceptor to add token
    this.apiClient.interceptors.request.use((config) => {
      console.log(config.method + " > " + config.url)
      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }
      return config;
    });

    // Response interceptor
    this.apiClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error) => {
        if (error.response?.status === 401) {
          // Handle token refresh or redirect
          console.log('Unauthorized, handle token refresh or redirect');
        } else if (error.response?.status === 403) {
          // Handle token refresh or redirect Unauthorized expired token
          console.log('Unauthorized, handle token refresh or redirect');

          try {
            const originalRequest = error.config;
            console.log('Unauthorized, trying to recover session...');
            /*
            // Try to refresh the token by calling recoverSession from AppContext
            const newToken = await this.recoverSession(); // Call recoverSession
            if (newToken) {
              this.token = newToken; // Set new token
              originalRequest.headers.Authorization = `Bearer ${newToken}`; // Update token in request headers
              return this.apiClient(originalRequest); // Retry original request
            } else {
              return Promise.reject(error); // If no token, fail
            }*/
          } catch (err) {
            return Promise.reject(err); // Token refresh failed, log out user or redirect to login
          }
        } else {
          return Promise.reject(error);
        }
      });
  }

  public setToken(token: string | null) {
    this.token = token;
  }

  // GET Methods

  public async getMe(): Promise<IUser> {
    try {
      const response = await this.apiClient.get('/api/user/') as ApiResponse<IUser>;
      return response.data;  // Here, response.data is of type Me
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }

  public async getOrganizationTree(organizationId: string): Promise<any> { // Adjust return type as needed
    try {
      const response = await this.apiClient.get(`/api/organization/${organizationId}/tree`) as ApiResponse<IOrganization>;
      return response.data
    } catch (error) {
      console.error(`Error fetching organization tree with ID ${organizationId}:`, error);
      throw error;
    }
  }

  public async getImage(organizationId: string, projectId: string, testcaseId: string, fileId: string): Promise<IFile> {
    try {
      return (await this.apiClient.get(`/api/organization/${organizationId}/project/${projectId}/testcase/${testcaseId}/file/${fileId}`)).data as IFile;
    } catch (error) {
      console.error(`Error fetching image with ID ${fileId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getAllImages(organizationId: string, projectId: string, testcaseId: string): Promise<IFile[]> {
    try {
      return (await this.apiClient.get(`/api/organization/${organizationId}/project/${projectId}/testcase/${testcaseId}/file/`)).data as IFile[];
    } catch (error) {
      console.error(`Error downloading images from testCase ${testcaseId} in project ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async downloadSuite(organizationId: string, projectId: string, suiteId: string, platform: string): Promise<void> {
    try {
      const response = await this.apiClient.get(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}/download/${platform}`, {
        responseType: 'blob', // Important for handling binary data
      });

      // Create a new Blob object using the response data of the file
      const fileBlob = new Blob([response.data], { type: response.data.type });

      // Create a link element
      const link = document.createElement('a');

      // Create a URL for the Blob and set it as the href attribute
      const url = URL.createObjectURL(fileBlob);
      link.href = url;

      // Set the download attribute with a file name
      link.download = `${suiteId}-${platform}.zip`;

      // Append the link to the document body and trigger a click to start the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

    } catch (error) {
      console.error(`Error downloading files from suite ${suiteId} in project ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getSuiteTree(organizationId: string, projectId: string, suiteId: string): Promise<ISuite> {
    try {
      return (await this.apiClient.get(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}/tree`)).data as ISuite;
    } catch (error) {
      console.error(`Error fetching TC with ID ${suiteId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getTestCase(organizationId: string, projectId: string, testCaseId: string): Promise<ITestCase> {
    try {
      return (await this.apiClient.get(`/api/organization/${organizationId}/project/${projectId}/testcase/${testCaseId}`)).data as ITestCase;
    } catch (error) {
      console.error(`Error fetching TC with ID ${testCaseId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getAllActionWords(): Promise<IActionWord[]> {
    try {
      return (await this.apiClient.get('/api/actionwords/')).data as IActionWord[];
    } catch (error) {
      console.error('Error fetching action words:', error);
      throw error;
    }
  }

  //POST

  public async uploadImage(organizationId: string, projectId: string, testcaseId: string, formData: FormData): Promise<IFile> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/${projectId}/testcase/${testcaseId}/file/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })).data as IFile;
    } catch (error) {
      console.error(`Error uploading image to project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async AIGenerate(organizationId: string, projectId: string, testcaseId: string, formData: AIgenerateModel): Promise<Action[]> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/${projectId}/testcase/${testcaseId}/ai/generate`, formData
      )).data;
    } catch (error) {
      console.error(`Error generating test case with AI:`, error);
      throw error;
    }
  }


  public async createProject(organizationId: string, data: createProjectModel): Promise<IProject> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/`, data)).data as IProject;
    } catch (error) {
      console.error(`Error creating project for organization ${organizationId}:`, error);
      throw error;
    }
  }


  public async createProjectSuite(organizationId: string, projectId: string, data: createSuiteModel): Promise<ISuite> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/${projectId}/suite`, data)).data as ISuite;
    } catch (error) {
      console.error(`Error creating suite for project ${projectId}:`, error);
      throw error;
    }
  }


  public async createTest(organizationId: string, projectId: string, data: createTestModel): Promise<ITestCase> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/${projectId}/testcase`, data)).data as ITestCase;
    } catch (error) {
      console.error(`Error creating Test for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async addTestToSuite(organizationId: string, projectId: string, suiteId: string, testId: string): Promise<ITestCase> {
    try {
      return (await this.apiClient.post(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}/testcase/${testId}`)).data as ITestCase;
    } catch (error) {
      console.error(`Error adding Test to suite ${suiteId}:`, error);
      throw error;
    }
  }

  public async removeTestFromSuite(organizationId: string, projectId: string, suiteId: string, testId: string): Promise<ITestCase> {
    try {
      return (await this.apiClient.delete(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}/testcase/${testId}`)).data as ITestCase;
    } catch (error) {
      console.error(`Error removing Test from suite ${suiteId}:`, error);
      throw error;
    }
  }


  //Add test to suite
  //remove test to suite



  //PUT

  public async updateProject(organizationId: string, projectId: string, data: createProjectModel): Promise<IProject> {
    try {
      return (await this.apiClient.put(`/api/organization/${organizationId}/project/${projectId}`, data)).data as IProject;
    } catch (error) {
      console.error(`Error updating project with ID ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async updateProjectSuite(organizationId: string, projectId: string, suiteId: string, data: createSuiteModel): Promise<ISuite> {
    try {
      return (await this.apiClient.put(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}`, data)).data as ISuite;
    } catch (error) {
      console.error(`Error updating project with ID ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  //DELETE

  public async deleteProject(organizationId: string, projectId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/api/organization/${organizationId}/project/${projectId}`);
    } catch (error) {
      console.error(`Error deleting project with ID ${projectId} from organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async deleteProjectSuite(organizationId: string, projectId: string, suiteId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/api/organization/${organizationId}/project/${projectId}/suite/${suiteId}`);
    } catch (error) {
      console.error(`Error deleting suite ${suiteId} project with ID ${projectId} from organization ${organizationId}:`, error);
      throw error;
    }
  }




  /************************************************************************/

  //UNUSED
  public async getOrganization(organizationId: string): Promise<IOrganization> {
    try {
      const response = await this.apiClient.get(`/api/organization/${organizationId}`) as ApiResponse<IOrganization>;
      return response.data;
    } catch (error) {
      console.error(`Error fetching organization with ID ${organizationId}:`, error);
      throw error;
    }
  }



  public async getActionWordById(actionWordId: string): Promise<ActionWord> {
    try {
      return (await this.apiClient.get<ApiResponse<ActionWord>>(`/api/actionwords/${actionWordId}`)).data;
    } catch (error) {
      console.error(`Error fetching action word with ID ${actionWordId}:`, error);
      throw error;
    }
  }

  public async getPaymentMethods(): Promise<PaymentMethod[]> {
    try {
      return (await this.apiClient.get<ApiResponse<PaymentMethod[]>>('info/payment-methods')).data.data;
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      throw error;
    }
  }

  public async getTiers(): Promise<Tier[]> {
    try {
      return (await this.apiClient.get<ApiResponse<Tier[]>>('info/tiers')).data.data;
    } catch (error) {
      console.error('Error fetching tiers:', error);
      throw error;
    }
  }




  public async getProjects(organizationId: string): Promise<Project[]> {
    try {
      return (await this.apiClient.get<ApiResponse<Project[]>>(`/api/organization/${organizationId}/project/`)).data.data;
    } catch (error) {
      console.error(`Error fetching projects for organization ID ${organizationId}:`, error);
      throw error;
    }
  }

  public async getProjectById(organizationId: string, projectId: string): Promise<Project> {
    try {
      return (await this.apiClient.get<ApiResponse<Project>>(`/api/organization/${organizationId}/project/${projectId}`)).data;
    } catch (error) {
      console.error(`Error fetching project with ID ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getProjectTree(organizationId: string, projectId: string): Promise<any> { // Adjust return type as needed
    try {
      return (await this.apiClient.get<ApiResponse<any>>(`/api/organization/${organizationId}/project/${projectId}/tree`)).data;
    } catch (error) {
      console.error(`Error fetching project tree with ID ${projectId} for organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getImages(organizationId: string, projectId: string): Promise<Image[]> {
    try {
      return (await this.apiClient.get<ApiResponse<Image[]>>(`/api/organization/${organizationId}/project/${projectId}/file/`)).data.data;
    } catch (error) {
      console.error(`Error fetching images for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getTaskById(organizationId: string, projectId: string, taskId: string): Promise<Task> {
    try {
      return (await this.apiClient.get<ApiResponse<Task>>(`/api/organization/${organizationId}/project/${projectId}/tasks/${taskId}`)).data;
    } catch (error) {
      console.error(`Error fetching task with ID ${taskId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getTasks(organizationId: string, projectId: string): Promise<Task[]> {
    try {
      return (await this.apiClient.get<ApiResponse<Task[]>>(`/api/organization/${organizationId}/project/${projectId}/tasks`)).data.data;
    } catch (error) {
      console.error(`Error fetching tasks for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async getTaskCompletionStatus(organizationId: string, projectId: string, taskId: string): Promise<any> { // Adjust return type as needed
    try {
      return (await this.apiClient.get<ApiResponse<any>>(`/api/organization/${organizationId}/project/${projectId}/tasks/${taskId}/complete`)).data;
    } catch (error) {
      console.error(`Error fetching task completion status for task ${taskId} in project ${projectId} of organization ${organizationId}:`, error);
      throw error;
    }
  }

  // POST Methods
  public async createActionWord(data: createActionWordModel): Promise<ActionWord> {
    try {
      return (await this.apiClient.post<ApiResponse<ActionWord>>('/admin/actionwords', data)).data;
    } catch (error) {
      console.error('Error creating action word:', error);
      throw error;
    }
  }

  public async createPaymentMethod(data: createPaymentMethodModel): Promise<PaymentMethod> {
    try {
      return (await this.apiClient.post<ApiResponse<PaymentMethod>>('/admin/payment-method', data)).data;
    } catch (error) {
      console.error('Error creating payment method:', error);
      throw error;
    }
  }

  public async createTier(data: createTierModel): Promise<Tier> {
    try {
      return (await this.apiClient.post<ApiResponse<Tier>>('/admin/tier', data)).data;
    } catch (error) {
      console.error('Error creating tier:', error);
      throw error;
    }
  }

  public async createUser(data: createUserModel): Promise<User> {
    try {
      return (await this.apiClient.post<ApiResponse<User>>('}/', data)).data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  }

  public async createUserFromInvite(data: createUserFromInviteModel): Promise<User> {
    try {
      return (await this.apiClient.post<ApiResponse<User>>('}/fromInvite', data)).data;
    } catch (error) {
      console.error('Error creating user from invite:', error);
      throw error;
    }
  }

  public async createOrganization(data: createOrganizationModel): Promise<Organization> {
    try {
      return (await this.apiClient.post<ApiResponse<Organization>>('/api/organization/', data)).data;
    } catch (error) {
      console.error('Error creating organization:', error);
      throw error;
    }
  }

  public async inviteUser(organizationId: string, data: inviteUserModel): Promise<any> { // Adjust return type as needed
    try {
      return (await this.apiClient.post<ApiResponse<any>>(`/api/organization/${organizationId}/users/invite`, data)).data;
    } catch (error) {
      console.error(`Error inviting user to organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async acceptInvite(organizationId: string, data: acceptInviteModel): Promise<void> {
    try {
      await this.apiClient.post<void>(`/api/organization/${organizationId}/users/accept`, data);
    } catch (error) {
      console.error(`Error accepting invite for organization ${organizationId}:`, error);
      throw error;
    }
  }





  public async createTask(organizationId: string, projectId: string, data: createTaskModel): Promise<Task> {
    try {
      return (await this.apiClient.post<ApiResponse<Task>>(`/api/organization/${organizationId}/project/${projectId}/tasks`, data)).data;
    } catch (error) {
      console.error(`Error creating task for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  // PUT Methods
  public async updateActionWord(actionWordId: string, data: updateActionWordModel): Promise<ActionWord> {
    try {
      return (await this.apiClient.put<ApiResponse<ActionWord>>(`/admin/actionwords/${actionWordId}`, data)).data;
    } catch (error) {
      console.error(`Error updating action word with ID ${actionWordId}:`, error);
      throw error;
    }
  }

  public async updatePaymentMethod(paymentMethodId: string, data: updatePaymentMethodModel): Promise<PaymentMethod> {
    try {
      return (await this.apiClient.put<ApiResponse<PaymentMethod>>(`/admin/payment-method/${paymentMethodId}`, data)).data;
    } catch (error) {
      console.error(`Error updating payment method with ID ${paymentMethodId}:`, error);
      throw error;
    }
  }

  public async updateTier(tierId: string, data: updateTierModel): Promise<Tier> {
    try {
      return (await this.apiClient.put<ApiResponse<Tier>>(`/admin/tier/${tierId}`, data)).data;
    } catch (error) {
      console.error(`Error updating tier with ID ${tierId}:`, error);
      throw error;
    }
  }

  public async updateUser(userId: string, data: updateUserModel): Promise<User> {
    try {
      return (await this.apiClient.put<ApiResponse<User>>(`}/users/${userId}`, data)).data;
    } catch (error) {
      console.error(`Error updating user with ID ${userId}:`, error);
      throw error;
    }
  }

  public async updateOrganization(organizationId: string, data: updateOrganizationModel): Promise<Organization> {
    try {
      return (await this.apiClient.put<ApiResponse<Organization>>(`/api/organization/${organizationId}`, data)).data;
    } catch (error) {
      console.error(`Error updating organization with ID ${organizationId}:`, error);
      throw error;
    }
  }



  public async updateImage(organizationId: string, projectId: string, fileId: string, data: updateImageModel): Promise<Image> {
    try {
      return (await this.apiClient.put<ApiResponse<Image>>(`/api/organization/${organizationId}/project/${projectId}/file/${fileId}`, data)).data;
    } catch (error) {
      console.error(`Error updating image with ID ${fileId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async updateTask(organizationId: string, projectId: string, taskId: string, data: updateTaskModel): Promise<Task> {
    try {
      return (await this.apiClient.put<ApiResponse<Task>>(`/api/organization/${organizationId}/project/${projectId}/tasks/${taskId}`, data)).data;
    } catch (error) {
      console.error(`Error updating task with ID ${taskId} for project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  // DELETE Methods
  public async deleteActionWord(actionWordId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/admin/actionwords/${actionWordId}`);
    } catch (error) {
      console.error(`Error deleting action word with ID ${actionWordId}:`, error);
      throw error;
    }
  }

  public async deletePaymentMethod(paymentMethodId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/admin/payment-method/${paymentMethodId}`);
    } catch (error) {
      console.error(`Error deleting payment method with ID ${paymentMethodId}:`, error);
      throw error;
    }
  }

  public async deleteTier(tierId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/admin/tier/${tierId}`);
    } catch (error) {
      console.error(`Error deleting tier with ID ${tierId}:`, error);
      throw error;
    }
  }

  public async deleteUser(organizationId: string, userId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/api/organization/${organizationId}/users/${userId}`);
    } catch (error) {
      console.error(`Error deleting user with ID ${userId} from organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async deleteImage(organizationId: string, projectId: string, fileId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/api/organization/${organizationId}/project/${projectId}/file/${fileId}`);
    } catch (error) {
      console.error(`Error deleting image with ID ${fileId} from project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

  public async deleteTask(organizationId: string, projectId: string, taskId: string): Promise<void> {
    try {
      await this.apiClient.delete(`/api/organization/${organizationId}/project/${projectId}/tasks/${taskId}`);
    } catch (error) {
      console.error(`Error deleting task with ID ${taskId} from project ${projectId} in organization ${organizationId}:`, error);
      throw error;
    }
  }

}

export default new BackendService();


// Sample types for demonstration. Replace these with actual types from your application.
interface Organization { /* properties */ }
interface ActionWord { /* properties */ }
interface PaymentMethod { /* properties */ }
interface Tier { /* properties */ }
interface Project { /* properties */ }
interface Image { /* properties */ }
interface Task { /* properties */ }
interface User { /* properties */ }

// Example Models for POST requests
interface createActionWordModel { /* properties */ }
interface createPaymentMethodModel { /* properties */ }
interface createTierModel { /* properties */ }
interface createUserModel { /* properties */ }
interface createUserFromInviteModel { /* properties */ }
interface createOrganizationModel { /* properties */ }
interface inviteUserModel { /* properties */ }
interface acceptInviteModel { /* properties */ }
interface createProjectModel {
  name: string,
  description: string | undefined
}
interface createSuiteModel {
  name: string,
  description: string | undefined
}
interface createTestModel {
  name: string,
  description: string | undefined
}
interface createTaskModel { /* properties */ }
interface updateActionWordModel { /* properties */ }
interface updatePaymentMethodModel { /* properties */ }
interface updateTierModel { /* properties */ }
interface updateUserModel { /* properties */ }
interface updateOrganizationModel { /* properties */ }
interface updateProjectModel { /* properties */ }
interface updateImageModel { /* properties */ }
interface updateTaskModel { /* properties */ }

export interface AIgenerateModel {
  instructions: string;
  credentials: {
    username: string,
    password: string
  }
}