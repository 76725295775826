import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericModal from '../../components/GenericModal';
import { useApp } from '../../contexts/AppContext';
import CreateProjectForm from './modals/CreateProjectForm';
import CreateSuiteForm from './modals/CreateSuiteForm';
import DeleteProjectForm from './modals/DeleteProjectForm';

function ProjectView(): React.JSX.Element {
    const { project, organization, loading, fetchOrganization } = useApp();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isSuiteModalOpen, setSuiteModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleMoreActionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddSuite = () => {
        setSuiteModalOpen(true)
    };

    const handleRename = () => {
        setEditModalOpen(true);
        handleMenuClose();
    };

    const handleDelete = () => {
        setDeleteModalOpen(true);
        handleMenuClose();
    };

    const handleSuiteClick = (suiteId: string) => {
        if (project) {
            navigate(`/project/${project._id}/${suiteId}`);
        }
    };

    const handleDeleteSuccess = () => {
        setDeleteModalOpen(false);
        fetchOrganization(organization!._id)
        navigate('/');
    };

    const handleModalClose = () => {
        //Update 
        fetchOrganization(organization!._id)
        setEditModalOpen(false);
    };

    const handleSuiteModalClose = () => {
        //Update 
        fetchOrganization(organization!._id)
        setSuiteModalOpen(false);
    };

    return (
        <>
            {/* Project Header */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ height: '20px' }}>
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
                    <Link color="inherit" onClick={() => navigate('/')}>
                        Organization Home
                    </Link>
                    <Typography sx={{ flexGrow: 1 }}>
                        {project?.name}
                    </Typography>
                </Breadcrumbs>

                <Box justifyContent="flex-end" display="flex" alignItems="center" sx={{ minWidth: "200px" }}>
                    <IconButton onClick={handleMoreActionsClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                        <MenuItem onClick={handleRename}>Rename</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddSuite}
                        startIcon={<AddIcon />}
                    >
                        Add Suite
                    </Button>
                </Box>
            </Box>

            {/* Display Suites */}
            <Grid container>
                {loading ? (
                    <>
                        {[1, 2].map((skeleton) => (
                            <Grid item xs={12} sm={12} md={6} key={skeleton} sx={{ padding: 2 }}>
                                <Card>
                                    <CardContent>
                                        <Skeleton variant="text" height={40} width="80%" />
                                        <Skeleton variant="text" height={30} width="60%" sx={{ mt: 2 }} />
                                        <Skeleton variant="rectangular" height={118} sx={{ mt: 2 }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </>
                ) : project && project.suites.length === 0 ? (
                    <Box>
                        <Grid item xs={12} sm={12} md={12} key={project?.description} sx={{ padding: 2 }}>
                            {project?.description}
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ height: '20vh' }} // Center vertically and horizontally
                            >
                                There are no suites yet
                            </Box>
                        </Grid>
                    </Box>

                ) : (
                    <>
                        <Grid item xs={12} sm={12} md={12} key={project?.description} sx={{ padding: 2 }}>
                            {project?.description}
                        </Grid>
                        {project?.suites.map((suite: any) => (
                            <Grid item xs={12} sm={12} md={6} key={suite._id} sx={{ padding: 2 }}>

                                <Card sx={{ minWidth: 275 }} onClick={() => handleSuiteClick(suite._id)}>
                                    <CardActionArea>
                                        <CardContent sx={{ cursor: 'pointer' }}>
                                            <Typography variant="h5" component="div">
                                                {suite.name}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                Number of test cases: {suite.testCases.length}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>

            {/* Generic Modal for Delete Project */}
            <GenericModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                content={<DeleteProjectForm
                    onDelete={handleDeleteSuccess}
                    organization={organization!} project={project}
                />}
                title="Delete Project"
            />
            {/* Generic Modal for Edit Project */}
            <GenericModal
                isOpen={isEditModalOpen}
                onClose={() => setEditModalOpen(false)}
                content={<CreateProjectForm
                    onSuccess={handleModalClose}
                    organization={organization!} project={project}
                />}
                title={project?.name || 'Edit Project'}
            />
            {/* Generic Modal for New Suite */}
            <GenericModal
                isOpen={isSuiteModalOpen}
                onClose={() => setSuiteModalOpen(false)}
                content={<CreateSuiteForm
                    onSuccess={handleSuiteModalClose}
                    organization={organization!} project={project} suite={undefined}
                />}
                title={'Create Suite'}
            />

        </>
    );
}

export default ProjectView;