import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useApp } from './contexts/AppContext'; // Import AuthProvider and useAuth
import { ProjectProvider } from './contexts/ProjectContext';
import Layout from './views/Layout';
import Account from './views/private/AccountView';
import BuilderView from './views/private/BuilderView';
import ContinousIntegration from './views/private/ContinousIntegrationView';
import Credentials from './views/private/CredentialsView';
import DashboardView from './views/private/DashboardView';
import Documentation from './views/private/DocumentationView';
import Help from './views/private/Help';
import Organization from './views/private/OrganizationView';
import ProjectSuiteView from './views/private/ProjectSuiteView';
import ProjectTestCaseView from './views/private/ProjectTestCaseView';
import ProjectView from './views/private/ProjectView';
import Upgrade from './views/private/Upgrade';
import LoginView from './views/public/LoginView';
import NotFound from './views/public/NotFoundView';
import RegisterView from './views/public/RegisterView';

const AppRouter: React.FC = () => {
    const { token } = useApp();

    return (
        <>
            {token ? (
                <Routes>
                    <Route path="/" element={
                        <ProjectProvider>
                            <DashboardView />
                        </ProjectProvider>
                    } />
                    <Route path="/project/:projectId" element={
                        <ProjectProvider>
                            <ProjectView />
                        </ProjectProvider>
                    } />
                    <Route path="/project/:projectId/:suiteId" element={
                        <ProjectProvider>
                            <ProjectSuiteView />
                        </ProjectProvider>
                    } />
                    <Route path="/project/:projectId/:suiteId/:testCaseId" element={
                        <ProjectProvider>
                            <ProjectTestCaseView />
                        </ProjectProvider>
                    } />
                    <Route path="/project/:projectId/create" element={
                        <ProjectProvider>
                            <BuilderView />
                        </ProjectProvider>
                    } />

                    <Route path="/project/:projectId/ci" element={
                        <ProjectProvider>
                            <ContinousIntegration />
                        </ProjectProvider>
                    } />
                    <Route path="/project/:projectId/credentials" element={
                        <ProjectProvider>
                            <Credentials />
                        </ProjectProvider>
                    } />
                    <Route path="/organization" element={<Layout showDrawer={true}><Organization /></Layout>} />
                    <Route path="/documentation" element={<Layout showDrawer={true}><Documentation /></Layout>} />
                    <Route path="/help" element={<Layout showDrawer={true}><Help /></Layout>} />
                    <Route path="/upgrade" element={<Layout showDrawer={true}><Upgrade /></Layout>} />
                    <Route path="/account" element={<Layout showDrawer={false}><Account /></Layout>} />
                    <Route path="*" element={<Layout showDrawer={false}><NotFound /></Layout>} />


                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<LoginView />} />
                    <Route path="/register" element={<RegisterView />} />
                    <Route path="*" element={<LoginView />} />
                </Routes>
            )}
        </>
    );
};

export default AppRouter;