import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext'; // Import useAuth

export default function AvatarMenu(): React.JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);
  const { token, logout, user } = useApp(); // Destructure token and logout from AuthContext
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout(); // Use logout from AuthContext
      navigate('/')
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleAccount = async () => {
    navigate('/account')
  };

  const handleOrganization = async () => {
    navigate('/organization')

  };

  return (
    <>
      <Avatar sx={{ cursor: 'pointer' }} onClick={handleClick}>
        AL
      </Avatar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleAccount}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Account</Typography>
        </MenuItem>
        <MenuItem onClick={handleOrganization}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Organization</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ color: 'red' }}>
          <ListItemIcon sx={{ color: 'red' }}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}