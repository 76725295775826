import { IOrganization, IProject, ISuite } from '@models/Backend';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import BackendService from '../../../services/BackendService';


interface DeleteSuiteFormProps {
    onDelete: () => void;
    organization: IOrganization,
    project: IProject | null | undefined
    suite: ISuite | null | undefined
}

const DeleteSuiteForm: React.FC<DeleteSuiteFormProps> = ({
    onDelete,
    organization,
    project,
    suite
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        setIsDisabled(value !== suite?.name); // Enable delete button only if input matches project name
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            if (organization && project && suite) {
                await BackendService.deleteProjectSuite(organization._id, project._id, suite?._id); // Make the delete request
                onDelete(); // Call the callback function after successful deletion
            }
        } catch (error) {
            setError('Failed to delete suite. Please try again.');
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <Box>
            {error && <Alert severity="error">{error}</Alert>}
            <Typography variant="body1" mb={2}>
                Please enter the suite name <strong style={{ color: 'red' }}>{suite?.name}</strong> to confirm deletion.
            </Typography>
            <TextField
                label="Suite Name"
                value={inputValue}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isDisabled || isDeleting}
                fullWidth
            >
                {isDeleting ? <CircularProgress size={24} /> : 'Delete Suite'}
            </Button>
        </Box>
    );
};

export default DeleteSuiteForm;