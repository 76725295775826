import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { IOrganization, IProject, ISuite } from '../../../models/Backend';
import BackendService from '../../../services/BackendService'; // Import AxiosService

interface CreateTestFormProps {
    onSuccess: () => void;  // Callback prop to close the modal or handle other success actions  
    organization: IOrganization,
    project: IProject | null | undefined
    suite: ISuite | null | undefined
}

const CreateProjectForm: React.FC<CreateTestFormProps> = ({
    onSuccess,
    organization,
    project,
    suite
}) => {

    const [testName, settestName] = useState('');
    const [testDescription, settestDescription] = useState('');

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSaveProject = async () => {
        // Validate project name
        if (testName.trim() === '') {
            setError('Project name is required');
            return;
        }

        // Reset error and start loading
        setError(null);
        setLoading(true);

        handleCreateProjectSuite()
    }

    const handleCreateProjectSuite = async () => {


        try {
            const test = await BackendService.createTest(organization!._id, project!._id, { name: testName, description: testDescription })

            if (suite && test) {
                //add it to the selected suite if needed
                await BackendService.addTestToSuite(organization!._id, project!._id, suite._id, test._id)
            }
            onSuccess(); // Close modal or handle success logic outside

            console.log('Test created:', testName);
        } catch (err) {
            // Handle any errors during project creation
            setError('Failed to create test. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            {error && <Alert severity="error">{error}</Alert>}

            <TextField
                label="Test Name"
                value={testName}
                onChange={(e) => settestName(e.target.value)}
                fullWidth
                required
                margin="normal"
                disabled={loading}
            />

            <TextField
                label="Test Description"
                value={testDescription}
                onChange={(e) => settestDescription(e.target.value)}
                fullWidth
                margin="normal"
                disabled={loading}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveProject}
                fullWidth
                disabled={loading}
            >
                {loading ? 'Creating...' : 'Create Test'}
            </Button>
        </Box>
    );
};

export default CreateProjectForm;