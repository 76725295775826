import React, { useState } from 'react';
import ActionPreviewList from '../../components/Builder/ActionPreviewList';
import ActionWordSelector from '../../components/Builder/ActionWordSelector';
import CodePreview from '../../components/Builder/CodePreview';
import TestCaseBuilder from '../../components/Builder/TestCaseBuilder';

import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { Action, TestCase } from '../../models/Local';

import { useApp } from '../../contexts/AppContext';
function TestCaseView(): React.JSX.Element {
  const { organization, project, suite, testCase } = useApp();

  // Initialize state with a function to set `isReusable` properly
  const [testCaseInstance, setTestCase] = useState<TestCase>({
    name: 'Test case',
    description: '',
    actions: null,
    organization: organization?._id,
    project: project?._id
  });


  // Handler to toggle `isReusable`
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

    setTestCase(prevTestCase => ({
      ...prevTestCase,
      isReusable: checked
    }));
  };

  const addActionWord = (action: Action) => {
    const order = testCaseInstance.actions?.length || 0
    action.order = order + 1

    setTestCase(prevTestCase => ({
      ...prevTestCase,
      actions: [
        ...prevTestCase.actions || [],
        action
      ]
    }));
  };

  const handleSave = () => {
    // Define your save action here
    console.log('Save action triggered');
  };

  return (
    <Grid container spacing={2}>
      {/* Left Grid Item */}
      <Grid md={8} xs={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Suits
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href="/"
          >
            Authentication
          </Link>
          <Typography color="text.primary">Unsaved</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid md={4} xs={4}>
        <Box display="flex" sx={{ flaot: 'right' }}>

          <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSave} sx={{ marginLeft: 2 }}>
            Save
          </Button>
        </Box>
      </Grid>
      <Grid xs={12} md={8}>
        <TestCaseBuilder testCase={testCaseInstance} setTestCase={setTestCase} />


        <Paper elevation={1} sx={{ padding: 2, mt: 2 }}>
          <ActionWordSelector addActionWord={addActionWord} />
        </Paper>
        <CodePreview testCase={testCaseInstance} />
      </Grid>

      {/* Right Grid Item */}
      <Grid xs={12} md={4}>
        <Paper elevation={1} sx={{ padding: 2, mt: 2 }}>
          <ActionPreviewList testCase={testCaseInstance} />
        </Paper>

      </Grid>
    </Grid>
  );
}

export default TestCaseView;