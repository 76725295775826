import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericModal from '../../components/GenericModal';
import { useApp } from '../../contexts/AppContext'; // Ensure this path is correct
import BackendService from '../../services/BackendService';
import CreateSuiteForm from './modals/CreateSuiteForm';
import CreateTestForm from './modals/CreateTestForm';
import DeleteSuiteForm from './modals/DeleteSuiteForm';


function ProjectSuiteView(): React.JSX.Element {
    const { organization, project, suite, fetchOrganization } = useApp();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [actionMenuAnchor, setActionMenuAnchor] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true); // Loading state
    const [isSuiteModalOpen, setSuiteModalOpen] = useState(false);
    const [isDeleteSuiteModalOpen, setDeleteSuiteModalOpen] = useState(false);
    const [isCreateTestModalOpen, setCreateTestModalOpen] = useState(false);

    React.useEffect(() => {
        var isLoadded = suite?.testCases != undefined && suite?.testCases != null
        setLoading(!isLoadded);


    }, [suite]);

    const handleAddTest = () => {
        setCreateTestModalOpen(true)

    };

    const handleTestCaseClick = (testCaseId: string) => {
        if (project && suite) {
            navigate(`/project/${project._id}/${suite._id}/${testCaseId}`);
        }
    };

    const handleMoreActionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setActionMenuAnchor(null);
    };

    const handleRename = () => {
        // Handle rename logic
        setSuiteModalOpen(true)
        handleMenuClose();
    };

    const handleDelete = () => {
        // Handle delete logic
        setDeleteSuiteModalOpen(true)
        handleMenuClose();
    };

    const handleDownloadiOS = async () => {
        // Handle delete logic
        if (organization && project && suite) {
            const zip = await BackendService.downloadSuite(organization._id, project._id, suite._id, "iOS")
        }
        console.log('Delete clicked');
        handleMenuClose();
    };

    const handleDownloadAndroid = () => {
        // Handle delete logic
        console.log('Delete clicked');
        handleMenuClose();
    };

    const handleMoveRow = () => {
        // Handle move logic
        console.log('Move row clicked');
        handleMenuClose();
    };

    const handleDeleteRow = () => {
        // Handle move logic
        //removeTestFromSuite
        console.log('Delete row clicked');
        if (organization && project && suite) {

            selectionModel.forEach((rowId) => {
                console.log(rowId)
                /*
                const test = suite.testCases.at(rowId)

                //find the testId
                console.log(`Deleting row with ID: ${rowId}`);
                BackendService.removeTestFromSuite(organization._id, project._id, suite._id, testId)
                    .then(() => {
                        console.log(`Successfully deleted test case with ID: ${rowId}`);
                        fetchOrganization(organization._id); // Refresh the data after deletion
                    })
                    .catch((error) => {
                        console.error(`Error deleting test case with ID: ${rowId}`, error);
                    });

                // You can implement your deletion logic here, e.g., calling an API to delete the selected rows
                */
            });
        }

        handleMenuClose();
    };

    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setActionMenuAnchor(event.currentTarget);
    };

    const handleRowSelection = (newSelection: GridRowSelectionModel) => {
        setSelectionModel(newSelection);
    };

    const handleSuiteModalClose = () => {
        //Update 
        fetchOrganization(organization!._id)
        setSuiteModalOpen(false);
        setDeleteSuiteModalOpen(false);
    }

    const setCreateTestModalClose = () => {
        fetchOrganization(organization!._id)
        setCreateTestModalOpen(false);
    }

    // Define columns for the DataGrid
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1, // Make the Name column as wide as possible
            minWidth: 300,
            renderCell: (params) => (
                <Link
                    component="button"
                    onClick={() => handleTestCaseClick(params.row.id)}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {params.row.name}
                </Link>
            ),
        },
        { field: 'actions', headerName: 'Actions', width: 150 },
        { field: 'date', headerName: 'Updated', width: 300 },
    ];

    // Map test case IDs to rows
    const rows: GridRowsProp = suite?.testCases.map((testCase) => ({
        id: testCase._id,
        name: testCase.name, // Replace with actual data if available
        actions: testCase.actions.length, // Replace with actual status if available
        date: testCase.updatedAt // Replace with actual priority if available
    })) || [];

    return (
        <>

            {/* Project Header */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ height: '20px' }}>
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
                    <Link color="inherit" onClick={() => navigate('/')}>
                        Organization Home
                    </Link>
                    {project ? (
                        <Link color="inherit" onClick={() => navigate(`/project/${project?._id}`)}>
                            {project?.name}
                        </Link>
                    ) : (
                        <Skeleton animation="wave" width="100px" variant="text" />
                    )}
                    {suite ? (
                        <Typography sx={{ flexGrow: 1 }}>
                            {suite.name}
                        </Typography>
                    ) : (<Skeleton animation="wave" width="100px" variant="text" />)}

                </Breadcrumbs>


                <Box justifyContent="flex-end" display="flex" alignItems="center" sx={{ minWidth: "200px" }}>
                    <IconButton onClick={handleMoreActionsClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleDownloadiOS}>Download iOS</MenuItem>
                        <MenuItem onClick={handleDownloadAndroid}>Download Android</MenuItem>
                        <Divider />
                        <MenuItem onClick={handleRename}>Rename</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                    {/* Add Project Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddTest}
                        startIcon={<AddIcon />}
                    >
                        Add Test
                    </Button>

                </Box>
            </Box>



            {/* Display Test Cases in DataGrid */}
            {loading ? (
                <>
                    <Skeleton animation="wave" variant="rounded" height={50} sx={{ mb: 1 }} />
                    <Skeleton animation="wave" variant="rectangular" height={160} sx={{}} />
                </>

            ) : (
                <>
                    {/* Action Menu for Selected Rows */}

                    <Box display="flex" justifyContent="flex-end" sx={{ mb: 1 }}>

                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleMoveRow}
                            sx={{ mr: 1 }}
                            disabled={selectionModel.length === 0}

                        >
                            Move
                        </Button>

                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleDeleteRow}
                            disabled={selectionModel.length === 0}

                        >
                            Delete
                        </Button>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            checkboxSelection
                            onRowSelectionModelChange={handleRowSelection}
                            rowSelectionModel={selectionModel}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 50 },
                                },
                            }}
                            pageSizeOptions={[50, 100]}
                        />
                    </Box>
                </>
            )}

            {/* Generic Modal for New Suite */}
            <GenericModal
                isOpen={isSuiteModalOpen}
                onClose={() => setSuiteModalOpen(false)}
                content={<CreateSuiteForm
                    onSuccess={handleSuiteModalClose}
                    organization={organization!} project={project} suite={suite}
                />}
                title={'Create Suite'}
            />

            {/* Generic Modal for Delete Suite */}
            <GenericModal
                isOpen={isDeleteSuiteModalOpen}
                onClose={() => setDeleteSuiteModalOpen(false)}
                content={<DeleteSuiteForm
                    onDelete={handleSuiteModalClose}
                    organization={organization!} project={project} suite={suite}
                />}
                title={'Create Suite'}
            />

            {/* Generic Modal for create Test */}
            <GenericModal
                isOpen={isCreateTestModalOpen}
                onClose={() => setCreateTestModalOpen(false)}
                content={<CreateTestForm
                    onSuccess={setCreateTestModalClose}
                    organization={organization!} project={project} suite={suite}
                />}
                title={'Create Testcase'}
            />


        </>
    );
}

export default ProjectSuiteView;