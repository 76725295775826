import { User, browserLocalPersistence, browserSessionPersistence, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { IOrganization, IProject, ISuite, ITestCase, IUser } from "../models/Backend";
import BackendService from '../services/BackendService'; // Import AxiosService
import { auth } from '../services/FirebaseService'; // Adjust path as needed
import AppContextType from './AppContextType';

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const [user, setUser] = useState<IUser | null | undefined>(undefined);
  const [organization, setOrganization,] = useState<IOrganization | null | undefined>(undefined);
  const [project, setProject] = useState<IProject | null | undefined>(undefined);
  const [suite, setSuite] = useState<ISuite | null | undefined>(undefined);
  const [testCase, setTestCase] = useState<ITestCase | null | undefined>(undefined);

  //Check current login State
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
      console.log("onAuthStateChanged")
      if (user) {
        const token = await user.getIdToken(true);
        setToken(token);
        BackendService.setToken(token); // Set token in Axios service
        await fetchMe();
      } else {
        setToken(null);
        setOrganization(null);
        BackendService.setToken(null); // Clear token in Axios service
      }
      setLoading(false);
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);


  const fetchMe = async () => {

    try {
      const me = await BackendService.getMe()
      setUser(me)
      //Todo: do not hardcore, if needed save the latest selected organization
      if (me?.organizations[0]._id) {
        const organizationId = me.organizations[0]._id
        fetchOrganization(organizationId)

      }

    } catch (error) {
      setUser(null)
      setOrganization(null); // Optionally set organization to null on error
    }

  };

  const fetchOrganization = async (organizationId: string) => {
    try {
      const organization = await BackendService.getOrganizationTree(organizationId)
      setOrganization(organization);
    } catch (error) {
      setOrganization(null); // Optionally set organization to null on error
    }
  }

  const setAuthPersistence = async (rememberMe: boolean) => {
    const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await auth.setPersistence(persistence);
  };

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      await setAuthPersistence(rememberMe); // Set persistence before login
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken(true);
      setToken(token);
      BackendService.setToken(token); // Set token in Axios service
      await fetchMe();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const register = async (email: string, password: string, rememberMe: boolean) => {
    try {
      await setAuthPersistence(rememberMe); // Set persistence before login
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken(true);
      setToken(token);
      BackendService.setToken(token); // Set token in Axios service
      await fetchMe();
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setToken(null);
      BackendService.setToken(null); // Clear token in Axios service
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  const recoverSession = async (): Promise<string | null> => {
    try {
      // Get the current user from Firebase Auth
      const currentUser = auth.currentUser;

      if (currentUser) {
        // Get a new token
        const newToken = await currentUser.getIdToken(true); // `true` forces token refresh
        setToken(newToken);
        BackendService.setToken(newToken); // Update the token in Axios service
        return newToken;
      } else {
        // No current user, session expired
        console.log("No current user. Session may have expired.");
        await logout(); // Log the user out if session recovery fails
        return null;
      }
    } catch (error) {
      console.error("Failed to recover session", error);
      await logout(); // Log the user out if the token refresh fails
      return null;
    }
  };


  return (
    <AppContext.Provider value={{
      loading,
      token, setToken,
      user, setUser,
      organization, setOrganization, fetchOrganization,
      project, setProject,
      suite, setSuite,
      testCase, setTestCase,
      setPersistence: setAuthPersistence,
      login, register, logout,
      recoverSession
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): AppContextType => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};