// ProjectContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackendService from '../services/BackendService';
import Layout from '../views/Layout';
import { useApp } from './AppContext'; // Adjust the path as needed

interface ProjectContextProps {
    loading: boolean;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { projectId, suiteId, testCaseId } = useParams<{ projectId: string; suiteId: string; testCaseId: string }>();
    const { organization, suite, setProject, setSuite, setTestCase } = useApp();
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loadProjectSuiteTestCase = async () => {
            if (organization && projectId) {
                const foundProject = organization.projects.find(proj => proj._id === projectId);
                if (foundProject) {

                    setProject(foundProject);
                    if (suiteId) {
                        const foundSuite = foundProject.suites.find(s => s._id === suiteId);
                        if (foundSuite) {
                            const fetchedSuite = await BackendService.getSuiteTree(organization._id, projectId, suiteId)
                            setSuite(fetchedSuite);
                            if (testCaseId) {
                                const foundTestCase = fetchedSuite!.testCases.find(tc => tc._id === testCaseId);
                                if (foundTestCase) {
                                    setTestCase(foundTestCase)
                                } else {
                                    navigate(`/project/${projectId}/suite/${suiteId}`);
                                }
                            } else {
                                setTestCase(null)
                            }

                            setLoading(false);
                        } else {
                            navigate(`/project/${projectId}`);
                        }
                    } else {
                        setSuite(null)
                    }
                    setLoading(false);
                } else {
                    setProject(null)
                    navigate('/');
                }
                setLoading(false);
            } else {
                setLoading(false)
            }
        };

        loadProjectSuiteTestCase();
    }, [organization, projectId, suiteId, testCaseId, setProject, navigate]);

    return (
        <ProjectContext.Provider value={{ loading }}>
            <Layout showDrawer={true}>
                {children}
            </Layout>
        </ProjectContext.Provider>
    );
};

export const useProjectContext = (): ProjectContextProps => {
    const context = useContext(ProjectContext);
    if (!context) {
        throw new Error('useProjectContext must be used within a ProjectProvider');
    }
    return context;
};