import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Upgrade(): React.JSX.Element {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80%"
            sx={{ bgcolor: 'background.paper', p: 3 }}
        >
            <Typography variant="h1" gutterBottom>
                Upgrade
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleGoHome}
                sx={{ mt: 2 }}
            >
                Go Home
            </Button>
        </Box>
    );
}

export default Upgrade;