// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUzsLvf1iNokfxWHvRQTF-4WrN9AolrR0",
  authDomain: "nativesting.firebaseapp.com",
  projectId: "nativesting",
  storageBucket: "nativesting.appspot.com",
  messagingSenderId: "249839159717",
  appId: "1:249839159717:web:deb891b85429cbc93c004c",
  measurementId: "G-GWPT0D4LFD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { analytics, auth };
