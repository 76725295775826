import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { IActionWord } from '../../models/Backend';
import { Action, Parameter } from '../../models/Local';
import BackendService from '../../services/BackendService'; // Import AxiosService

interface Props {
  addActionWord: (action: Action) => void;
}

const ActionWordSelector: React.FC<Props> = ({ addActionWord }) => {
  const [selectedAction, setSelectedAction] = useState<IActionWord | null>(null);
  const [parameters, setParameters] = useState<Parameter[] | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [fetchedActionWords, setActionWords] = useState<IActionWord[]>([]);

  useEffect(() => {
    const fetchActionWords = async () => {
      try {
        const response = await BackendService.getAllActionWords();
        setActionWords(response);
      } catch (error) {
        console.error('Error fetching action words:', error);
      }
    };

    fetchActionWords();
  }, []);

  const handleParameterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Update the specific parameter by name
    const updatedParameters = parameters?.map((param) =>
      param.name === name ? { ...param, value } : param
    );

    // Update the state with the new parameters array
    setParameters(updatedParameters!.length > 0 ? updatedParameters! : null);


    // Update errors
    setErrors({
      ...errors,
      [name]: value === '',
    });
  };

  const validateParameters = () => {
    if (!selectedAction) return false;
    const newErrors: { [key: string]: boolean } = {};
    let isValid = true;
    //TODO: what needs to be done here to validate?

    setErrors(newErrors);
    return isValid;
  };

  const handleAddAction = () => {
    if (validateParameters()) {
      if (selectedAction) {
        const action: Action = {
          order: 1,
          type: 'actionword',
          referenceId: selectedAction._id,
          action: selectedAction.name,
          parameters: parameters,
        };
        addActionWord(action);
        setSelectedAction(null);
        setParameters(null);
        setErrors({});
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && validateParameters()) {
      handleAddAction();
    }
  };

  const handleActionChange = (event: any, newValue: any) => {
    if( newValue as IActionWord){
    // Initialize parameters with empty values based on the selected action's parameters
    const initialParameters = (newValue as IActionWord).parameters.map(param => ({
      name: param.name,
      value: param.type === 'string' ? '' : 0, // Set empty string for strings, 0 for numbers
    }));

    setSelectedAction(newValue);
    setParameters(initialParameters);  // Initialize with empty values
    setErrors({});  // Reset errors
    }else {
      setSelectedAction(null)
    }
  };

  return (
    <Box>
      <Autocomplete
        disablePortal
        autoHighlight
        options={fetchedActionWords}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.category}
        sx={{ width: '100%', mt: 2 }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              component="li"
              {...optionProps}
            >
              <Typography fontSize={'14px'} fontStyle={'bold'}>{option.name}</Typography>
              <Box sx={{ flexGrow: 1 }} /> {/* Spacer */}
              <Typography fontSize={'10px'} fontStyle={'italic'} sx={{ marginTop: '4px' }}>{option.description}</Typography>
            </Box>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Add Action" />}
        value={selectedAction}
        onChange={handleActionChange}
      />

      {selectedAction && (
        <>
          {selectedAction.parameters.map((param, index) => (
            <TextField
              key={param.name}
              required
              autoComplete="off"
              name={param.name}
              id={param.name}
              label={param.name}
              type={param.type === 'number' ? 'number' : 'text'}
              value={parameters!.find(parameter => parameter.name === param.name)?.value}
              variant="outlined"
              onChange={handleParameterChange}
              onKeyPress={handleKeyPress}
              sx={{ mr: 2, mb: 2, mt: 2 }}
              error={!!errors[param.name]}
              helperText={errors[param.name] ? 'This field is required' : ''}
            />
          ))}
          <br />
          <Button
            variant="contained"
            size="large"
            onClick={handleAddAction}
            sx={{ mt: 2 }}
          //disabled={!selectedAction || !selectedAction.parameters.every((param) => parameters[param.name])}
          >
            Add
          </Button>
        </>
      )}
    </Box>
  );
};

export default ActionWordSelector;