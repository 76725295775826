import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { IOrganization, IProject, ISuite } from '../../../models/Backend';
import BackendService from '../../../services/BackendService'; // Import AxiosService

interface CreateProjectFormProps {
  onSuccess: () => void;  // Callback prop to close the modal or handle other success actions  
  organization: IOrganization,
  project: IProject | null | undefined
  suite: ISuite | null | undefined
}

const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
  onSuccess,
  organization,
  project,
  suite
}) => {

  const [suiteName, setSuiteName] = useState(suite?.name || '');
  const [suiteDescription, setsuiteDescription] = useState(suite?.description || '');

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSaveProject = async () => {
    // Validate project name
    if (suiteName.trim() === '') {
      setError('Project name is required');
      return;
    }

    // Reset error and start loading
    setError(null);
    setLoading(true);

    if (suite) {
      handleUpdateProjectSuite(suite)
    } else {
      handleCreateProjectSuite()
    }
  }

  const handleCreateProjectSuite = async () => {


    try {
      await BackendService.createProjectSuite(organization!._id, project!._id, { name: suiteName, description: suiteDescription })
      onSuccess(); // Close modal or handle success logic outside

      console.log('Suite created:', suiteName);
    } catch (err) {
      // Handle any errors during project creation
      setError('Failed to create suite. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProjectSuite = async (suite: ISuite) => {
    try {
      //TODO: Update the project
      await BackendService.updateProjectSuite(organization!._id, project!._id, suite._id, { name: suiteName, description: suiteDescription })
      onSuccess(); // Close modal or handle success logic outside

      console.log('Suite updated:', suiteName);
    } catch (err) {
      // Handle any errors during project creation
      setError('Failed to update suite. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}

      <TextField
        label="Suite Name"
        value={suiteName}
        onChange={(e) => setSuiteName(e.target.value)}
        fullWidth
        required
        margin="normal"
        disabled={loading}
      />

      <TextField
        label="Suite Description"
        value={suiteDescription}
        onChange={(e) => setsuiteDescription(e.target.value)}
        fullWidth
        margin="normal"
        disabled={loading}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveProject}
        fullWidth
        disabled={loading}
      >
        {suite ? loading ? 'Updating...' : 'Update Suite' : loading ? 'Creating...' : 'Create Suite'}
      </Button>
    </Box>
  );
};

export default CreateProjectForm;