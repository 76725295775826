import { IFile } from '@models/Backend';
import React from 'react';

interface ImageProps {
    file: IFile;
}

const ImageComponent: React.FC<ImageProps> = ({ file }) => {
    let base64Data: string;

    if (typeof file.data === 'string') {
        // If data is already a base64 string
        base64Data = file.data;
    } else {
        // Convert buffer to base64 string
        base64Data = bufferToBase64(file.data);
    }

    const imageUrl = `data:${file.contentType};base64,${base64Data}`;

    return (
        <img
            src={imageUrl}
            alt={file.description}
            style={{ maxWidth: '100%' }}
        />
    );
};

// Function to convert buffer to base64
const bufferToBase64 = (buffer: { data: number[] }): string => {
    const byteArray = new Uint8Array(buffer.data);
    const binaryString = Array.from(byteArray).map(byte => String.fromCharCode(byte)).join('');
    return window.btoa(binaryString);
};

export default ImageComponent;
