import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import React from 'react';
import { TestCase } from '../../models/Local';
import ActionPreview from './ActionPreview';

interface Props {
  testCase: TestCase;
}

const ActionPreviewList: React.FC<Props> = ({ testCase }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant='h6'>Actions</Typography>
      <List sx={{ width: '100%' }}>
        {testCase.actions == null || testCase.actions.length === 0 ? (
          <p>No actions added yet.</p>
        ) : (
          testCase.actions.map((action) => (
            <>
              <ActionPreview key={"preview_" + action.order} action={action} />
            </>
          ))
        )}
      </List>
    </Box>

  );
};

export default ActionPreviewList;