import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionPreviewList from '../../components/Builder/ActionPreviewList';
import ActionWordSelector from '../../components/Builder/ActionWordSelector';
import TestCaseBuilder from '../../components/Builder/TestCaseBuilder';
import { useApp } from '../../contexts/AppContext';
import { TestCase } from '../../models/Local';

import { Action } from '../../models/Local';
import DragAndDropUpload from '../Drag';

function ProjectTestCaseView(): React.JSX.Element {
  const { organization, project, suite, testCase, loading } = useApp();

  const navigate = useNavigate();
  const [testCaseInstance, setTestCase] = useState<TestCase>({
    name: 'Test case',
    description: '',
    actions: [],
    organization: organization?._id,
    project: project?._id
  });

  const handleSaveProject = () => {

  };

  const addActionWord = (action: Action) => {
    const order = testCaseInstance.actions?.length || 0
    action.order = order + 1

    setTestCase(prevTestCase => ({
      ...prevTestCase,
      actions: [
        ...prevTestCase.actions || [],
        action
      ]
    }));
  };

  const donateActionWords = (actions: Action[]) => {
    testCaseInstance.actions = []
    actions.forEach(action => {
      console.log(action)
      addActionWord(action)
    })
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <>
      {/* Breadcrumbs */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ height: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 0 }}>
          <Link color="inherit" onClick={() => navigate('/')}>
            Organization Home
          </Link>

          {project ? (
            <Link color="inherit" onClick={() => navigate(`/project/${project?._id}`)}>
              {project?.name}
            </Link>
          ) : (
            <Skeleton animation="wave" width="100px" variant="text" />
          )}
          {suite ? (
            <Link color="inherit" onClick={() => navigate(`/project/${project?._id}/suite/${suite?._id}`)}>
              {suite?.name}
            </Link>
          ) : (<Skeleton animation="wave" width="100px" variant="text" />)}

          {testCase ? (
            <Typography color="textPrimary">{testCase?.name}</Typography>

          ) : (<Skeleton animation="wave" width="100px" variant="text" />)}
        </Breadcrumbs>
        {/* Add Project Button */}
        <Box justifyContent="flex-end" display="flex" alignItems="center" sx={{ minWidth: "200px" }}>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveProject}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <TestCaseBuilder testCase={testCaseInstance} setTestCase={setTestCase} />
          <Paper elevation={1} sx={{ padding: 2, mt: 2 }}>
            <ActionPreviewList testCase={testCaseInstance} />
            <ActionWordSelector addActionWord={addActionWord} />
          </Paper>
        </Grid>
        <Grid item xs={4} md={4}>

          <Paper elevation={1} sx={{ padding: 2, mt: 2 }}>
            <DragAndDropUpload donateActionWords={donateActionWords} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectTestCaseView;


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
