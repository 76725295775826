import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import { TestCase } from '../../models/Local';

interface Props {
  testCase: TestCase;
}

const CodePreview: React.FC<Props> = ({ testCase }) => {
  return (
    <Box
      sx={{
        height: '100%', // Ensure full height
      }}>
      <Paper elevation={1}>
        <pre>{JSON.stringify(testCase, null, 2)}</pre>
      </Paper>
    </Box>
  );
};

export default CodePreview;